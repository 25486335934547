<template>
  <nav aria-label="breadcrumb" v-if="nav">
    <ol class="breadcrumb" v-if="items && items.length">
      <template v-for="(item, ix) in items">
        <li
          v-if="ix == nav.items.length - 1"
          v-bind:key="ix"
          class="breadcrumb-item active"
          aria-current="page"
        >
          {{ $tc((item && item.name) || "", 1) }}
          <template v-if="item.button">
            <router-link v-bind:to="item.button.url" class="item-button">
              <i :class="item.button.icon" :title="item.button.title"></i>
            </router-link>
          </template>
        </li>
        <li v-else class="breadcrumb-item" v-bind:key="ix">
          <router-link v-bind:to="item.url">{{
            $tc((item && item.name) || "", 1)
          }}</router-link>
          <template v-if="item.button">
            <router-link v-bind:to="item.button.url" class="item-button">
              <i :class="item.button.icon" :title="item.button.title"></i>
            </router-link>
          </template>
        </li>
      </template>
    </ol>
  </nav>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: {
    nav: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data() {
    return {};
  },
  computed: {
    items() {
      return (this?.nav?.items || []).filter(
        (item) => item?.name != "0" && item?.name != ""
      );
    }
  }
};
</script>
<style scoped>
.item-button {
  margin-left: 5px;
}
.item-button:hover {
  cursor: pointer;
  opacity: 0.8;
  color: #f39c12;
}
</style>
