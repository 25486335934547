<template>
  <div>
    <template v-if="hasSecurePort">
      <div class="row">
        <div class="col-sm-5 text-right h4 hidden-xs">
          {{ $t("ip_network_configuration") }}
        </div>
        <div class="col-xs-12 hidden-sm hidden-md hidden-lg h4">
          {{ $t("ip_network_configuration") }}
        </div>
        <FormCopyInput
          class="col-sm-4"
          label=" IP "
          :value="val('access_host', '-')"
          disabled
        />
      </div>
      <div class="row">
        <FormCopyInput
          class="col-sm-4"
          :label="$t('authenticated_port') + ' V0'"
          :value="val('connector_access_port', '-')"
          :hint="val('hint_connector_access_port')"
          disabled
        />
        <FormCopyInput
          class="col-sm-4"
          :label="$t('authenticated_port') + ' V1'"
          :value="val('connector_access_secure_port', '-')"
          :hint="val('hint_connector_access_secure_port')"
          disabled
        />
        <FormCopyInput
          class="col-sm-4"
          :label="$t('plug_port')"
          :value="val('plug_access_port', '-')"
          :hint="val('hint_plug_access_port')"
          disabled
        />
      </div>
    </template>
    <template v-else>
      <div class="h4">
        {{ $t("ip_network_configuration") }}
      </div>
      <div class="row">
        <FormCopyInput
          class="col-sm-4"
          label=" IP "
          :value="val('access_host', '-')"
          disabled
        />
        <FormCopyInput
          class="col-sm-4"
          :label="$t('port')"
          :value="val('connector_access_port', '-')"
          :hint="val('hint_connector_access_port')"
          disabled
        />
        <FormCopyInput
          class="col-sm-4"
          :label="$t('plug_port')"
          :value="val('plug_access_port', '-')"
          :hint="val('hint_plug_access_port')"
          disabled
        />
      </div>
    </template>
  </div>
</template>

<script>
import FormCopyInput from "@/components/registration/form-copy-input.vue";
export default {
  name: "FormEquipmentCLP",
  components: {
    FormCopyInput
  },
  props: {
    protocol: {
      type: Object,
      required: false,
      default: () => null
    },
    connector: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  computed: {
    hasSecurePort() {
      return this?.protocol?.connector_access_secure_port ? true : false;
    }
  },
  methods: {
    val(attr, dft) {
      return (this?.protocol || {})[attr] || dft || "";
    }
  }
};
</script>

<style scoped></style>
