import Vue from "vue";
import VueResource from "vue-resource";
import Auth from "@/services/auth.js";

Vue.use(VueResource);
/*
public methods:
  fetch - request a list of items
  get - request a single item
  save - request to save or update a single item
  remove - request to remove a single item
  duplicate - request an item copy
  */

const baseurl = ""; //v1  set to "" after conversion

const endpoints = {
  public: {
    fetch: "public_resource_access",
    get: "public_access",
    save: "public_resource_access",
    delete: "public_resource_access"
  },
  private: {
    fetch: "remote_access_tokens",
    get: "remote_access_tokens",
    save: "remote_access_tokens",
    delete: "remote_access_tokens"
  },
  access_token: {
    fetch: "access_tokens",
    get: "access_tokens",
    save: "access_tokens",
    delete: "access_tokens"
  }
};

export default class TokenService {
  constructor(token_type) {
    this.token_type = token_type;
    this.target = endpoints[token_type || ""] || null;
  }

  async fetch(query) {
    let self = this;
    return new Promise((resolve) => {
      if (!self.target) {
        resolve(null);
        return;
      }
      let url = `${baseurl}${self.target.fetch}/?format=json`;
      let auth = new Auth();
      if (query) {
        for (var prop in query) {
          let vlr = encodeURIComponent(query[prop]);
          url += `&${prop}=${vlr}`;
        }
      }
      let request = Vue.http.get(url, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          //console.log(error);
          let body = ("body" in error && error.body) || {};
          let msg = [];
          for (var i in body) {
            msg.push(i + ": " + body[i]);
          }
          resolve(msg.join("\n"));
        }
      );
    });
  }

  async save(payload) {
    let self = this;
    return new Promise((resolve) => {
      if (!self.target) {
        resolve(null);
        return;
      }
      let url = `${baseurl}${self.target.save}/`; //v1
      let request = null;
      let auth = new Auth();
      if ("id" in payload && payload.id) {
        request = Vue.http.patch(
          url + payload.id + "/",
          payload,
          auth.requestOptions(
            payload.etag ? { "If-Match": payload.etag } : null
          )
        );
      } else {
        request = Vue.http.post(url, payload, auth.requestOptions());
      }
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          //console.log(error);
          let body = ("body" in error && error.body) || {};
          let msg = [];
          for (var i in body) {
            msg.push(i + ": " + body[i]);
          }
          resolve(msg.join("\n"));
        }
      );
    });
  }

  async remove(payload) {
    let self = this;
    return new Promise((resolve) => {
      if (!self.target) {
        resolve(null);
        return;
      }
      let url = `${baseurl}${self.target.delete}/${payload.id}/?format=json`; //v1
      let auth = new Auth();
      Vue.http
        .delete(
          url,
          auth.requestOptions(
            payload.etag ? { "If-Match": payload.etag } : null
          )
        )
        .then(
          (response) => {
            resolve({});
          },
          (error) => {
            resolve(null);
          }
        );
    });
  }

  async get(id) {
    let self = this;
    return new Promise((resolve, reject) => {
      if (!self.target) {
        resolve(null);
        return;
      }
      let request = null;
      let url = `${baseurl}${self.target.get}/${id}/?format=json`; //v1
      if (self.token_type == "public") {
        request = Vue.http.get(url);
      } else {
        let auth = new Auth();
        request = Vue.http.get(url, auth.requestOptions());
      }
      // if (this.token_type == "token_type") {
      //   let url = `${baseurl}/${target.public.get}public_access/${id}/?format=json`; //v1
      //   request = Vue.http.get(url);
      // } else if (this.target == "access_tokens") {
      //   let url = `${baseurl}access_tokens/${id}/?format=json`; //v1
      //   request = Vue.http.get(url);
      // }
      request.then(
        (response) => {
          resolve(response.body);
        },
        (error) => {
          reject((error && error.body) || error);
        }
      );
    });
  }

  toPublicUrl(entry) {
    var app_origin = (Vue?.http?.options?.dashboard || "").replace(
      /\/static\//g,
      ""
    );
    //console.log(app_origin);
    if (app_origin) {
      app_origin = `https://${app_origin}`;
    } else {
      app_origin = document.location.origin;
    }
    return `${app_origin}/view/${entry.token}`;
  }
}
