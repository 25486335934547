<template>
  <div>
    <template v-if="isMQTT">
      <div class="row">
        <div class="form-group col-md-4 col-sm-6" v-if="showName">
          <label for="deviceName"
            >{{ $t(label || "device_name") }}
            <ToolTip
              :title="
                $parent.$t(
                  label ? 'hints.standard_device' : 'hints.device_name'
                )
              "
            />
          </label>
          <input
            type="text"
            class="form-control"
            data-testid="deviceName"
            v-model="deviceName"
            v-bind:disabled="isModelBased"
            id="deviceName"
            name="deviceName"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-4 col-sm-6">
          <label for="deviceLabel">
            {{ $t("topic") }} MQTT
            <ToolTip :title="$t('hints.mqtt_device_topic')" />
          </label>
          <div class="input-group">
            <div
              class="input-group-addon input-prefix-label"
              :style="{
                'background-color': isModelBased ? '#eee' : 'inherit'
              }"
            >
              {{ topicPrefix }}/
            </div>
            <input
              class="form-control no-focus-border"
              :value="deviceLabel"
              :disabled="isModelBased"
              @input="setLabel($event)"
              style="padding-left: 0; border-left: 0"
              id="deviceLabel"
            />
            <!-- <MQTTTopicSpan
              class="input-group-addon btn"
              :entry="{ ...device, connector: connector }"
            /> -->
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="form-group col-md-4 col-sm-6" v-if="showName">
          <label for="deviceName"
            >{{ $t(label || "device_name") }}
            <ToolTip
              :title="
                $parent.$t(
                  label ? 'hints.standard_device' : 'hints.device_name'
                )
              "
            />
          </label>
          <input
            type="text"
            class="form-control"
            data-testid="deviceName"
            v-model="deviceName"
            v-bind:disabled="isModelBased"
            id="deviceName"
            name="deviceName"
          />
        </div>
        <div class="form-group col-md-4 col-sm-6 col-xs-12">
          <label for="device_address">
            {{ $t("device_address") }}
            <ToolTip :title="$parent.$t('hints.device_address')" />
          </label>
          <input
            type="number"
            class="form-control text-right"
            data-testid="device-address"
            v-model="device.device_address"
            id="device_address"
            min="0"
            max="255"
            step="1"
            :disabled="isModelBased || isVirtual"
          />
        </div>
        <div class="form-group col-md-4 col-sm-6 col-xs-12">
          <div>
            <div class="text-bold">{{ $t("type") }}</div>
          </div>
          <label class="checkbox-inline">
            <input
              type="checkbox"
              data-testid="device-is-hi"
              v-model="device.is_hi_device"
              id="is_hi_device"
              :disabled="isModelBased || isVirtual"
            />
            {{ $t("is_hi_device") }}
            <ToolTip :title="$parent.$t('hints.is_hi_device')" />
          </label>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ToolTip from "@/components/tooltip.vue";
import { isMQTT } from "@/services/connector.js";
import { mqttTopic } from "@/services/equipment.js";
// import MQTTTopicSpan from "@/components/registration/mqtt-topic-span.vue";
export default {
  name: "DeviceEditorBasicForm",
  components: {
    ToolTip
    // MQTTTopicSpan
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: () => ""
    },
    isModelBased: {
      type: Boolean,
      required: false,
      default: () => false
    },
    connector: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      showName: false,
      device: {
        id: "",
        name: "",
        device_address: "0",
        is_hi_device: false,
        local_storage_timezone: "",
        data_collector_device_id: "",
        label: ""
      },
      busy: false
    };
  },
  computed: {
    protocolId() {
      return this?.connector?.protocol.id;
    },
    isMQTT() {
      return this.protocolId ? isMQTT(this?.connector) : false;
    },
    isVirtual() {
      return this.device.id !== this.device.data_collector_device_id;
    },
    topicPrefix() {
      return mqttTopic({ ...this.device, connector: this.connector }, -1);
    },
    deviceName: {
      set(value) {
        if (this.isMQTT) {
          if (
            !this.deviceLabel ||
            this.deviceLabel == this.$utils.asLabel(this.device.name)
          ) {
            this.deviceLabel = value;
          }
        } else {
          this.deviceLabel = "";
        }
        this.device.name = value;
      },
      get() {
        return this?.device?.name || "";
      }
    },
    deviceLabel: {
      set(value) {
        // this.$set(this.device, "label", this.$utils.asLabel(value));
        this.device.label = this.$utils.asLabel(value);
      },
      get() {
        return this?.device?.label || "";
      }
    }
  },
  watch: {
    protocolId(n) {
      this.deviceName = this.deviceName;
    },
    value(n) {
      if (
        !this.device.local_storage_timezone &&
        n &&
        n.local_storage_timezone
      ) {
        this.device.local_storage_timezone = n.local_storage_timezone;
      }
    }
  },
  methods: {
    setLabel($event) {
      if (this.isModelBased) return;
      this.deviceLabel = $event?.target?.value || "";
      $event.target.value = this.deviceLabel;
    }
  },
  mounted() {
    if ("name" in this.value) {
      this.showName = true;
    }
    this.$set(this, "device", this.value);
  }
};
</script>

<style scoped>
.input-prefix-label {
  padding-right: 0px;
  line-height: 1.42857143;
  font-weight: 600;
  color: #999;
}
</style>
