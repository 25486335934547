<template>
  <div class="form-group">
    <div class="input-group">
      <div
        class="input-group-addon clicable btn"
        v-bind:title="$t('copy', { item: label })"
        v-on:click.prevent.stop="copyToClipboard(value)"
      >
        <span
          >{{ $t(label) }}
          <ToolTip :title="$t(hint)" v-if="hint" />
        </span>
      </div>
      <input
        type="text"
        class="form-control text-center"
        v-bind="$attrs"
        :value="value"
      />
      <div
        class="input-group-addon btn hidden-sm"
        v-bind:title="$t('copy', { item: label })"
        v-on:click.prevent.stop="copyToClipboard(value)"
      >
        <i class="fa fa-copy"></i>
      </div>
    </div>
  </div>
</template>

<script>
import ToolTip from "@/components/tooltip.vue";
export default {
  name: "FormCopyInput",
  components: { ToolTip },
  props: {
    label: {
      type: String,
      required: false,
      default: ""
    },
    value: {
      type: [Number, String],
      required: false,
      default: ""
    },
    hint: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      iValue: ""
    };
  },
  watch: {
    value(n) {
      if (n != this.iValue) {
        this.iValue = n;
      }
    },
    iValue(n) {
      if (n != this.value) {
        this.$emit("input", n);
      }
    }
  },
  methods: {
    copyToClipboard(text) {
      this.clipboardText = text;
      this.$utils.toClipboard(text);
      let infoHtml = `${this.$t(this.label)} - ${this.$t("item_copied")}`;
      let options = {
        type: "success",
        icon: "copy",
        iconPack: "fontawesome",
        singleton: true,
        duration: 10000, // 10s
        position: "bottom-right",
        action: {
          icon: "fa-close",
          onClick: (e, me) => {
            me.goAway(0);
          }
        }
      };
      this.$toasted.clear();
      this.$toasted.show(infoHtml, options);
    }
  }
};
</script>

<style scoped>
.form-control {
  font-weight: bold;
}
.form-control[disabled] {
  background-color: white;
  opacity: 0.9;
}
</style>
