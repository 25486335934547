<template>
  <router-link
    class="text-info"
    :class="{ screen_column: !isModel }"
    :title="$t(isModel ? 'edit' : 'titles.access_dashboard')"
    :to="link"
  >
    <slot name="before" :item="item"></slot>
    <slot name="default" :item="item">
      <span>
        {{ (screen && screen.name) || item.name }}
      </span>
      <i class="fa fa-dashboard not-revealed" v-if="!isModel" />
    </slot>
    <slot name="after" :item="item"></slot>
  </router-link>
</template>

<script>
export default {
  name: "ScreenSelectionCell",
  props: {
    // item must be a connector or a device
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    connector() {
      return this.item.connector || this.item || null;
    },
    isModel() {
      return this?.connector?.base_model || false;
    },
    device() {
      return this?.item?.connector ? this.item : null;
    },
    link() {
      if (!this.connector) return "";
      if (this.isModel) {
        return this.device
          ? `/dashboard/edit/connector/${this.connector.id}/device/${this.device.id}`
          : `/dashboard/edit/connector/${this.connector.id}`;
      } else {
        return this.device && this.device.screen_id
          ? `/dashboard/equipment/${this.connector.id}/${this.item.screen_id}?device_id=${this.device.id}`
          : this.connector &&
            this?.screen?.id &&
            parseInt(this.connector.screen_id) == parseInt(this?.screen?.id)
          ? `/dashboard/equipment/${this.connector.id}`
          : `/dashboard/equipment/${this.connector.id}/${this?.screen?.id}`;
      }
    },
    screen() {
      let entry = null;
      if (this?.device || this?.connector) {
        let sid = this.device?.screen_id || this?.connector?.screen_id || "";
        if (sid) {
          entry = this.$store.getters["dashboard/screens"].find(
            ({ id }) => parseInt(id) == sid
          );
        }
      } else if (this?.item?.path !== undefined) {
        entry = this.item;
      }
      return entry;
    }
  }
};
</script>

<style scoped>
.screen_column {
  display: block;
  margin: -5px;
  padding: 5px;
  background-color: inherit;
}

.screen_column:hover {
  cursor: pointer;
  background-color: rgb(225, 243, 250);
}

.screen_column > i.not-revealed {
  margin-left: 5px;
  color: transparent;
}

.screen_column:hover > i.not-revealed {
  color: #337ab7;
  opacity: 0.7;
}
</style>
