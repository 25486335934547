<template>
  <section>
    <div class="form-box">
      <div class="row">
        <div class="col-md-3 col-sm-6 col-xs-12 form-group">
          <label>
            Token
            <Tooltip :title="$parent.$t('hints.remote_access_token_token')" />
          </label>
          <div class="input-group">
            <input
              type="text"
              disabled="disabled"
              class="form-control"
              v-model="tokenValue"
            />
            <div
              class="input-group-addon btn"
              @click="copyToken"
              :disabled="!id || !formData.token"
            >
              <i class="fa fa-copy"></i>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-2 col-xs-6 form-group">
          <label id="label_token_type">
            {{ $t("token_type") }}
            <Tooltip :title="$parent.$t('hints.remote_access_token_type')" />
          </label>
          <br/>
          <template
            v-for="(item, index) in tokenTypeList"
          >
            <label
              class="radio-input-label no-bold"
              :key="index"
              :title="$t(`hints.remote_access_token_type_${item.type.toLowerCase()}`)"
              :for="`token_type_${item.id}`"
            >
              <input
                type="radio"
                :id="`token_type_${item.id}`"
                :value="item.id"
                v-model="formData.remote_access_token_type_id"
              />
              {{ item.type }}
            </label>
            &nbsp;
          </template>
        </div> -->

        <div class="col-md-2 col-sm-6 col-xs-12 form-group">
          <label>
            {{ $t("expires_at") }}
            <Tooltip
              :title="$parent.$t('hints.remote_access_token_expires_at')"
            />
          </label>
          <DatetimePicker id="expires_at" v-model="formData.expires_at" />
        </div>

        <div class="col-md-7 col-sm-12 col-xs-12 form-group">
          <label>
            {{ $t("description") }}
            <Tooltip
              :title="$parent.$t('hints.remote_access_token_description')"
            />
          </label>
          <input
            type="text"
            class="form-control"
            :placeholder="$t('description') + ' (' + $t('required') + ')'"
            v-model="formData.description"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="btn-group pull-right">
            <button type="button" class="btn btn-default" @click="reset">
              <i class="fa fa-times"></i>
              {{ $t("cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="save"
              :disabled="!isValid"
            >
              <i class="fa fa-floppy-o"></i>
              {{ $t("save_token") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="margin-top: 10px">
      <div class="col-md-12">
        <SearchableTable
          class="searchable-table"
          v-if="items"
          :searchEnabled="false"
          :items="items"
          :fields="fields"
          :commands="commands"
          @command="onCommand"
          @select="onSelect"
        >
          <template v-slot:toggle="entry">
            <span @click.stop.prevent="toggleItem(entry.item)">
              {{ $t(entry.item.enabled ? "yes" : "no") }}
              <i
                :class="
                  busy
                    ? 'fa fa-refresh fa-spin'
                    : entry.item.enabled
                    ? 'fa fa-toggle-on text-green'
                    : 'fa fa-toggle-off'
                "
              ></i>
            </span>
          </template>
        </SearchableTable>
      </div>
    </div>
  </section>
</template>

<script>
import DatetimePicker from "@/components/widgets/datetime-picker";
import TokenService from "@/services/token.js";
import SearchableTable from "@/components/searchable-table.vue";
import Tooltip from "@/components/tooltip.vue";

const initialState = () => {
  return {
    formData: {
      enabled: true,
      remote_access_token_type_id: "",
      expires_at: null,
      description: ""
    },
    id: "",
    etag: "",
    busy: false,
    items: null
  };
};

export default {
  name: "FormConnectorPrivateToken",
  components: {
    DatetimePicker,
    SearchableTable,
    Tooltip
  },
  props: {
    connector_id: {
      type: Number,
      required: false,
      default: 3
    }
  },
  data() {
    return initialState();
  },
  watch: {
    busy(n) {
      this.$emit("loading", n);
    },
    tokenTypeList: {
      handler(n) {
        this.formData.remote_access_token_type_id = n && n[0] ? n[0].id : 0;
      },
      immediate: true,
      deep: true
    },
    expiresAt(n) {
      if (n != null) return;
      document.getElementById('expires_at').value = "";
    }
  },
  computed: {
    fields() {
      return [
        {
          name: "type",
          title: "type",
          parser: function(item) {
            return (
              item.remote_access_token_type &&
              item.remote_access_token_type.name
            );
          }
        },
        {
          name: "token",
          title: "token"
        },
        {
          name: "description",
          title: "description"
        },
        {
          name: "expires_at",
          title: "expires_at",
          parser: (item) => {
            let expires_at = item.expires_at;
            if (!expires_at) return this.$t("undefined");
            return moment(expires_at).format("DD/MM/YYYY HH:mm");
          }
        },
        {
          name: "status",
          title: "status",
          parser: (item) => {
            return item.status ? this.$t("active") : this.$t("inactive");
          }
        }
      ];
    },
    commands() {
      if (this.$can("manage", "TokenAcessoRemotoEscrita")) {
        return [
          {
            name: "toggleItem",
            title: (item) => {
              return this.$tc(item.enabled ? "enabled" : "disabled", 1);
            },
            icon: (item) => {
              return item.enabled
                ? "fa fa-toggle-on text-green"
                : "fa fa-toggle-off";
            }
          },
          { name: "edit", title: "edit", icon: "fa fa-edit" },
          { name: "copy", title: "copy", icon: "fa fa-copy" },
          { name: "remove", title: "remove", icon: "fa fa-trash" }
        ];
      }
      return [];
    },
    tokenTypeList() {
      return (
        (this.$root &&
          this.$root.config &&
          this.$root.config.references &&
          this.$root.config.references.remote_access_token_types) ||
        []
      );
    },
    references() {
      let ret = this?.$root?.config?.references?.public_access || {
        default_timeout: 5,
        min_timeout: 1,
        max_timeout: 15
      };
      return ret;
    },
    payload() {
      let payload = {
        connector_id: this.connector_id,
        description: this.formData.description,
        expires_at: this.formData.expires_at,
        remote_access_token_type_id: this.formData.remote_access_token_type_id,
        enabled: this.formData.enabled
      };
      if (this.id) {
        payload.id = this.id;
      }
      if (this.etag) {
        payload.etag = this.etag;
      }
      return payload;
    },
    expiresAt() {
      return this.formData.expires_at;
    },
    tokenValue() {
      return this.formData.token ?? `(${this.$tc("new", 2)})`
    },
    isValid() {
      return Boolean(
        this.formData.description
      )
    }
  },
  methods: {
    reset() {
      let items = JSON.parse(JSON.stringify(this.items));
      Object.assign(this.$data, initialState());
      this.items = items;
      this.formData.remote_access_token_type_id = this.tokenTypeList[0].id;
    },
    save() {
      if (!this.isValid) return;
      if (this.busy) return;
      this.busy = true;
      this.srv.save(this.payload).then((response) => {
        if (response) {
          this.reset();
          this.fetchTokens();
        }
      });
    },
    remove(item) {
      if (this.busy) return;
      this.busy = true;
      this.srv.remove(item).then(() => {
        this.reset();
        this.fetchTokens();
      });
    },
    copy(item) {
      this.$utils.toClipboard(item.token || "");
      this.toast(this.$t('token_copied_to_clipboard'), 'success');
    },
    copyToken() {
      if (!this.id || !this.formData.token) return;
      this.$utils.toClipboard(this.formData.token);
    },
    edit(item) {
      this.id = item.id;
      this.etag = item.etag;
      this.$set(this.formData, "token", item.token);
      this.$set(this.formData, "description", item.description ?? "");
      this.$set(this.formData, "expires_at", item.expires_at);
      this.$set(this.formData, "remote_access_token_type_id", item.remote_access_token_type.id);
      this.$set(this.formData, "enabled", item.enabled);
    },
    toggleItem(item) {
      for (var p in this.formData) {
        this.formData[p] = item[p];
      }
      this.formData.enabled = !this.formData.enabled;
      this.etag = item.etag;
      this.id = item.id;
      this.save();
    },
    onCommand(command) {
      if (command.name in this) {
        this[command.name](command.target);
      }
    },
    onSelect(item) {
      this.copy(item);
    },
    toast(msg, type) {
      this.$toasted.show(msg, {
        singleton: true,
        type: type == 'error' ? "error" : "success",
        icon: type == 'error' ? "fa-exclamation-triangle" : "fa-check",
        iconPack: "fontawesome",
        position: "bottom-right",
        duration: 1000
      });
    },
    fetchTokens() {
      let query = { connector_id: this.connector_id };
      this.srv.fetch(query).then((response) => {
        if (response && response.length) {
          this.$set(this, "items", response);
        } else {
          this.$set(this, "items", null);
        }
        this.$nextTick(() => {
          this.busy = false;
        });
      });
    }
  },
  mounted() {
    $('.radio-input-label').tooltip({
      html: true,
      sanitize: false,
      animation: false
    }).off("click").off("focus");
  },
  created() {
    this.srv = new TokenService("private");
    this.fetchTokens();
  }
};
</script>

<style scoped>
.form-box {
  border-radius: 5px;
  background-color: whitesmoke;
  border: 1px solid lightgray;
  padding: 20px 10px;
  margin: 0 0 20px 0;
}

.no-bold {
  font-weight: 400;
}

.searchable-table {
  overflow-x: auto;
}

label {
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
</style>
