<template>
<div class="form-group" :class="customClasses">
  <select v-model="iValue" class="form-control">
    <option 
      v-for="(option, ix) in rate_options"
      v-bind:key="ix"
      v-bind:value="option.value || ''"
    >
      {{  option.text }}
    </option>
  </select>
</div>
</template>

<script>
export default {
  name: "DataHistoryRateSelector",
  data() {
    return {
      iValue: '300', // Default = 5 minutos
      rate_options: [],
    };
  },
  props: {
    value: {
      required: true,
      default: () => null
    },
    allowNone: {
      type: Boolean,
      required: false,
      default: () => false
    },
    contract: {
      type: Object,
      required: true
    },
    connector: {
      type: Object,
      required: false
    },
    customClasses: {
      type: String
    }
  },
  watch: {
    value: {
      handler(n, o) {
        if (o && o == n) return;
        if (n) {
          let nn = n < this.contract_data_history_rate ? this.contract_data_history_rate : n;
          this.iValue = nn+'';
          if (n != nn) {
            this.$emit("input", nn);
          }
        } else {
          this.iValue = '';
        }
        
      },
      immediate: true
    },
    iValue: {
      handler(n) {
        this.$emit("input", n ? parseInt(n) : null);
      },
      deep: true
    },
  },
  computed: {
    contract_data_history_rate() {
      let data_history_rate = this?.contract?.data_history_rate;
      return data_history_rate != undefined ? data_history_rate : 300;
    }
  },
  created() {
    this.rate_options = [
      {value: '', text: ''},
      {value: '60', text: `1 ${this.$tc('minute', 1)}`},
      {value: '120', text: `2 ${this.$tc('minute', 2)}`},
      {value: '180', text: `3 ${this.$tc('minute', 3)}`},
      {value: '240', text: `4 ${this.$tc('minute', 4)}`},
      {value: '300', text: `5 ${this.$tc('minute', 5)} (${this.$t('default')})`},
      {value: '600', text: `10 ${this.$tc('minute', 10)}`},
      {value: '900', text: `15 ${this.$tc('minute', 15)}`},
      {value: '1800', text: `30 ${this.$tc('minute', 30)}`},
      {value: '3600', text: `1 ${this.$tc('hour', 1)}`},
      {value: '14400', text: `4 ${this.$tc('hour', 4)}`},
      {value: '28800', text: `8 ${this.$tc('hour', 8)}`},
      {value: '436320', text: `12 ${this.$tc('hour', 12)}`},
      {value: '86400', text: `24 ${this.$tc('hour', 24)}`},
    ];

    this.rate_options = this.rate_options.filter((item) => {
      return item.value == '' || parseInt(item.value) >= this.contract_data_history_rate;
    });

    if (!this.allowNone) {
      this.rate_options.shift()
    } else {
      let connector_rate = this.rate_options.find((item) => parseInt(item.value) == this.connector.data_history_rate);
      if (!connector_rate) connector_rate = this.rate_options.find((item) => parseInt(item.value) == this.contract_data_history_rate);
      this.rate_options[0].text = `(${this.$t('same_as_connector')}: ${connector_rate.text})`;
    }
  }
}
</script>