<template>
  <div class="qrcode" :class="forceDimensions ? 'force-dimensions' : ''">
    <a
      v-if="state == 'ready'"
      ref="download_link"
      :href="qr"
      :download="filename"
      :title="`${$t('download')}: ${filename}`"
    >
      <img class="qrcode-img" :src="qr" :style="styleCustom" />
    </a>
    <span v-else-if="state == 'busy'">
      <i class="fa fa-refresh fa-spin"></i>
    </span>
  </div>
</template>

<script>
import QRCode from "qrcode";

export default {
  name: "QRCode",
  props: {
    content: {
      type: String,
      default: "https://hitecnologia.com.br",
      required: false
    },
    styleCustom: {
      type: String,
      default: "",
      required: false
    },
    filename: {
      type: String,
      default: "qrcode.jpg",
      required: false
    },
    forceDimensions: {
      type: Boolean,
      default: true,
      required: false
    },
    config: {
      type: Object,
      default: () => ({
        errorCorrectionLevel: "L",
        type: "image/jpeg",
        quality: 0.3,
        margin: 1,
        width: 800,
        color: {
          dark: "#000",
          light: "#FFF"
        }
      }),
      required: false
    }
  },
  data() {
    return {
      state: "idle",
      details: "idle"
    };
  },
  watch: {
    state(n) {
      this.$emit("state", n);
    }
  },
  methods: {
    async generate() {
      try {
        this.state = "busy";
        this.details = "loading";
        await QRCode.toDataURL(this.content, this.config, (err, url) => {
          if (err) throw err;
          this.qr = url;
          this.state = "ready";
          this.details = "ready";
        });
      } catch (err) {
        this.details = err;
        this.state = "error";
      }
    },
    download() {
      if (this.state == "ready" && this?.$refs?.download_link) {
        this.$refs.download_link.click();
      }
    }
  },
  mounted() {
    this.generate();
  },
  created() {
    this.qr = ""; // not reactive
  }
};
</script>

<style>
.qrcode {
  padding: 0 !important;
  display: inline-block;
}

.force-dimensions {
  width: 300px;
  height: 300px;
}

.qrcode img {
  width: 100%;
}
</style>