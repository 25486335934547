import Vue from "vue";
import VueResource from "vue-resource";
import Auth from "@/services/auth.js";
import { parseMessage } from "@/utils";

Vue.use(VueResource);

/*
backend entry
{
  data_src_id: {  
     connector_dst_id: [ data_dst_id, data_dst_id, ... ],
     connector_dst_id: [ data_dst_id, ... ],
     ... 
  }
}

sample:
{
  "1": { "20": ["2001"], "30": ["3001"] },
  "2": { "10": ["1002"], "30": ["3002"] },
  "3": { "10": ["1003"], "20": ["2003"] },
  "4": { "10": ["1004"], "20": ["2004"], "30": ["3004"] },
  "5": { "10": ["1005"], "20": ["2005"], "30": ["3005"] },
  "6": { "10": ["1006"], "20": ["2006"], "30": ["3006"] }
}

internally use: (destinationConnectorList)
[
  {
    id: 10, // connector id
    mappedData: {
      "1": null, // source => target
      "2": 1002,
      "3": 1003,
      "4": 1004,
      "5": 1005,
      "6": 1006
    }
  },
  {
    id: 20, // connector id
    mappedData: {
      "1": 2001,
      "2": null,
      "3": 2003,
      "4": 2004,
      "5": 2005,
      "6": 2006
    }
  },
  {
    id: 30, // connector id
    mappedData: {
      "1": 3001,
      "2": 3002,
      "3": null,
      "4": 3004,
      "5": 3005,
      "6": 3006
    }
  }
]
*/
const baseurl = ""; //v1  set to "" after conversion

export default class DataMirroring {
  async save(payload) {
    return new Promise((resolve) => {
      let request;
      let entry = JSON.parse(JSON.stringify(payload));
      let url = `${baseurl}connectors/${entry.id}/data_mapping/`; // entry.id == connectorId
      delete entry.id;
      let auth = new Auth();
      if (entry?.etag) {
        let etag = entry?.etag;
        delete entry.etag;
        request = Vue.http.post(
          url,
          entry,
          auth.requestOptions(payload.etag ? { "If-Match": etag } : null)
        );
      } else {
        request = Vue.http.post(url, entry, auth.requestOptions());
      }
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          let msg = [],
            body = error.body || {};
          if (body.detail) msg = body.detail;
          else msg = Object.entries(body);
          resolve(msg);
        }
      );
    });
  }

  async get(id) {
    return new Promise((resolve) => {
      let url = `${baseurl}connectors/${id}/data_mapping/?format=json`; //v1
      let auth = new Auth();
      Vue.http.get(url, auth.requestOptions()).then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async remove(payload) {
    return new Promise((resolve) => {
      let url = `${baseurl}connectors/${payload.id}/data_mapping/?format=json`; //v1
      let auth = new Auth();
      Vue.http
        .delete(
          url,
          auth.requestOptions(
            payload.etag ? { "If-Match": payload.etag } : null
          )
        )
        .then(
          () => {
            resolve({});
          },
          (error) => {
            resolve(
              error.body?.detail ||
                parseMessage(error.body ?? {}) ||
                "Failed to delete"
            );
          }
        );
    });
  }

  // returns initial mappedData object (null)
  defaultMappedData(entry) {
    let ret = {};
    if (entry) {
      if (entry.length) {
        // entry is a list of data
        (entry || []).forEach(({ id }) => {
          ret[id] = null;
        });
      } else {
        // entry is an object
        for (var data_src_id in entry) {
          ret[data_src_id] = null;
        }
      }
    }
    return ret;
  }

  // converts api returned entry to local list
  entry2destinationConnectorList(entry) {
    var result = [];
    if (!entry) return result;
    var data_src_id, connector_dst_id;

    for (data_src_id in entry) {
      for (connector_dst_id in entry[data_src_id]) {
        //console.log(`${data_src_id} ${connector_dst_id}`);
        let connMap = result.find(
          ({ id }) => parseInt(connector_dst_id) == parseInt(id)
        );
        if (!connMap) {
          connMap = {
            id: connector_dst_id,
            mappedData: this.defaultMappedData(entry)
          };
          result.push(connMap);
        }
      }
    }
    for (data_src_id in entry) {
      for (connector_dst_id in entry[data_src_id]) {
        let connMap = result.find(
          ({ id }) => parseInt(connector_dst_id) == parseInt(id)
        );
        if (connMap) {
          let targetList = entry[data_src_id][connector_dst_id] || [];
          // console.log(`${data_src_id} ${connector_dst_id}`);
          // console.log(connMap);
          // console.log(targetList);
          targetList.forEach((id) => {
            connMap.mappedData[data_src_id] = id;
          });
        }
      }
    }
    return result;
  }

  // converts local list to api entry
  destinationConnectorList2Entry(lst) {
    let entry = null;
    (lst || []).forEach((connector) => {
      if (connector?.mappedData) {
        entry = entry || {};
        for (var srcDataId in connector?.mappedData || {}) {
          let target = connector?.mappedData[srcDataId] || null;
          if (target) {
            entry[srcDataId] = entry[srcDataId] || {};
            entry[srcDataId][connector.id] =
              entry[srcDataId][connector.id] || [];
            entry[srcDataId][connector.id].push(target);
          }
        }
      }
    });
    return entry;
  }

  test() {
    // entry is the api layout
    var entry = {
      "1": { "20": ["2001"], "30": ["3001"] },
      "2": { "10": ["1002"], "30": ["3002"] },
      "3": { "10": ["1003"], "20": ["2003"] },
      "4": { "10": ["1004"], "20": ["2004"], "30": ["3004"] },
      "5": { "10": ["1005"], "20": ["2005"], "30": ["3005"] },
      "6": { "10": ["1006"], "20": ["2006"], "30": ["3006"] }
    };
    console.log(entry);
    // from
    let lst = this.entry2destinationConnectorList(entry);
    console.log(lst);
    // to
    var payload = this.destinationConnectorList2Entry(lst);
    console.log(payload);
  }
}
